<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">测量记录</h3>
    <div class="detail">
      <div class="flex  backReturn">
        <h4 class="title">测量记录</h4>
        <!--        <div>-->
        <el-button size="small" plain class="return" v-if="status==51" @click="$emit('changePage', 2, null,2)">新增记录
        </el-button>
        <!--          <el-button size="small" plain class="return" v-if="endMode">记录结束</el-button>-->
        <!--        </div>-->
      </div>
      <el-table
          border
          :data="tableData"
          class="record-table"
          :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
          height="calc(100vh - 500px)">
        <el-table-column
            label="记录日期" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.createTime">{{ scope.row.createTime | formatDay }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="location"
            label="操作室地点" width="150"
            show-overflow-tooltip>
        </el-table-column>
        <el-table-column
            label="使用仪器型号" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-for="item in scope.row.instrument">{{ item.instruModel }}、</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="createName"
            label="记录人">
        </el-table-column>
        <el-table-column
            prop="reviewName"
            label="复核人">
        </el-table-column>
        <el-table-column
            label="状态" width="110">
          <template slot-scope="scope">
            <!--            给药状态  0：草稿  1：给药完成  2：复核同意  3：复核不同意-->
            <el-button size="small" class="suspended" v-if="scope.row.state==0">草稿</el-button>
            <el-button size="small" class="experimentCo" v-else-if="scope.row.state==1">待复核</el-button>
            <el-button size="small" class="finished" v-else-if="scope.row.state==2">复核通过</el-button>
            <el-button size="small" class="approval" v-else-if="scope.row.state==3">复核退回</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button v-if="(scope.row.state==0||scope.row.state==3)&&scope.row.check" type="text"
                       @click="edit(scope.row.id)">编辑
            </el-button>
            <el-button type="text" v-if="scope.row.state==1&&user.userId==scope.row.reviewer"
                       @click="toDetail(scope.row.id)">复核
            </el-button>
            <el-button type="text" v-else @click="toDetail(scope.row.id)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex pagination ">
        <el-pagination
            prev-text="<"
            next-text=">"
            @size-change="handleSizeChange"
            @current-change="skipPage"
            :current-page="page.current"
            :page-size="page.size"
            layout="prev, pager, next"
            :total="page.total">
        </el-pagination>
        <span class="total">共计{{ Math.ceil(page.total / page.size) }}页</span>
      </div>
    </div>
  </div>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "measurementList",
  props: ['id', 'status'],
  data() {
    return {
      tableData: [],
      page: {
        current: 1,
        size: 10,
        total: 10,
      },
      // endMode: false,
    }
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    })
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      let that = this
      let params = {
        pageSize: that.page.size,
        pageNum: that.page.current,
      }
      this.$get('/subject/measure/byPage/' + this.id, params).then(res => {
        if (res.status == 200) {
          let a = true
          if (res.data.records.length > 0) {
            res.data.records.forEach(item => {
              item.instrument = JSON.parse(item.instrument)
              /*if (item.state != 2) {
                a = false
              }*/
              // 登陆者和创建者是自己 为true
              item.check = this.user.userId == item.createBy ? true : false
            })
            // 1.草稿状态并且记录人不是自己  2：复核人不是自己并且记录人不是自己
            // (this.user.userId != res.data.records[i].reviewer || res.data.records[i].state == 0) && this.user.userId != res.data.records[i].createBy
            for (let i = res.data.records.length - 1; i >= 0; i--) {
              if (res.data.records[i].state == 0 && this.user.userId != res.data.records[i].createBy) {
                res.data.records.splice(i, 1)
              }
            }
            // this.endMode = a ? true : false
          }
          this.tableData = res.data.records;
          this.page.current = res.data.current;
          this.page.total = res.data.total;
          this.page.size = res.data.size;
        }
      }).catch(() => {
        this.$message.error('获取数据失败')
      })
    },
    // 每页多少条
    handleSizeChange(val) {
      this.page.size = val;
    },
    // 当前页
    skipPage(current) {
      this.page.current = current;
      this.getData();
    },
    edit(measureId) {
      this.$emit('changePage', 2, measureId, 2)
    },
    toDetail(measureId) {
      this.$emit('changePage', 3, measureId, 2)
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";
</style>
